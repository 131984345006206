.hero-container {
    height: calc(100vh - 140px);
    background-image: url(../images/galaxy-bg.jpeg);
    scroll-snap-align: center;
    align-items: center;
    position: relative;
    background-size: cover;
    z-index: 1;
    cursor: pointer;
  }
  
  canvas {
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    .hero-container {
      height: calc(100vh - 180px);
    }
  }
  
  