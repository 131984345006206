.container {
  height: 60px;
  background-color: #242424;
  display: flex;
  align-items: center;
}

.footer-inner-container {
  display: flex;
  justify-content: space-between;
  height: 50%;
  width: 100%;
  align-items: center;
  padding: 0 30px;
  margin: 20px 0;
}


.flex {
    display: flex;
    align-items: center;
}

.socials {
    flex: 2.5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.socials a i {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2596be;
    border-radius: 50%;
    color: #242424;
    font-size: 1.5rem;
    transition: all 0.2s ease-in-out;
    width: 2rem;
    height: 2rem;
  } 

  .socials a:hover i {
    transform: rotate(20deg);
  }

  a {
    text-decoration: none;
  }

  .socials a:focus i {
    outline: none;
  }
  
a:visited {
    color: #fff;
  }  

.copyright {
    flex: 6.5;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.copyright p{
    font-size: 0.9rem;
    font-family: Arial, Helvetica, sans-serif;
}

.logo {
    color: #fff;
    flex: 2.5;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    background: #2596be;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .fa-laptop-code {
    margin-left: 0.5rem;
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {

    .container {
      height: 100px;
    }
    .footer-inner-container {
      flex-direction: column;
      height: auto;
      padding: 20px;
    }

    .logo {
        margin-left: 0;
    }
    .logo p {
        font-size: 1rem;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .copyright p{
        font-size: 0.6rem;
        margin-bottom: 10px;
    }

    .fa-laptop-code {
        margin-left: 0.5rem;
        font-size: 1rem;
        margin-bottom: 10px;
        margin-top: 5px;
      }

    .socials a i {
        width: 1.2rem;
        height: 1.2rem;
        font-size: 0.8rem;
        margin-bottom: 5px;
    }


  }