.about-container {
    height: calc(100vh - 140px);
    background-image: url(../images/galaxy-bg.jpeg);
    background-size: cover;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  .about-inner-container {
    scroll-snap-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .aboutme-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  
  .aboutme-flex:first-child {
    padding-left: 4%;
    padding-right: 4%;
  }
  
  .aboutme-text {
    width: 100%;
    height: 100%;
  }

  .aboutme-design {
    flex: 1.2;
    position: relative;
  }

  .stack {
    position: absolute;
  }

  .aboutme-text h1 {
    color: #2596be;
    font-size: 3em;
    font-family: headerFont;
    text-decoration: underline #2596be;
    padding-bottom: 10px;
    text-shadow: 2px 3px 4px hotpink;
    align-self: flex-start;
  }

  .aboutme-text p {
    font-size: 1.2em;
    font-family: textFont;
    color: white;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 1.2px;
    align-self: flex-start;
  }

  .pad {
    padding-bottom: 60px;
  }

  @font-face {
    font-family: headerFont;
    src: url(../fonts/aboutme-header-font.otf)
  }
  @font-face {
    font-family: textFont;
    src: url(../fonts/aboutme-text-font.otf)
  }

  .aboutme-design {
    width: 100%;
    height: 100%;
  }

  .aboutme-design img {
    animation: animate 1s infinite ease alternate;
    height: 450px;
    width: 370px;
    padding-left: 150px;
  }

  @keyframes animate {
    to {
        transform: translateY(20px);
    }
  }
  
  @media screen and (max-width: 767px) {
    .about-inner-container {
      flex-direction: column;
    }
    
    .aboutme-flex:first-child {
      margin-right: 0;
      margin-bottom: 30px;
      flex: 2;
    }

    .aboutme-text {
        padding: none;
    }

    .pad {
        padding-bottom: 20px;
    }

    .aboutme-text h1 {
        font-size: 2.2em;
    }

    .aboutme-text p {
        font-size: 0.95em;
        -webkit-text-stroke-width: 0.8px;
    }
  }

  @media screen and (max-width: 400px) {
    .about-inner-container {
      flex-direction: column;
    }
    
    .aboutme-flex:first-child {
      margin-right: 0;
      margin-bottom: 30px;
      flex: 2;
    }

    .aboutme-design img {
        height: 350px;
        width: 295px;
    }

    .aboutme-text {
        padding: none;
    }

    .pad {
        padding-bottom: 10px;
    }

    .aboutme-text h1 {
        font-size: 1.8em;
    }

    .aboutme-text p {
        font-size: 0.7em;
        -webkit-text-stroke-width: 0.6px;
    }
  }

  @media screen and (max-width: 500px) {
    .aboutme-design img {
      height: 250px;
      width: 210px;
    }
  }
  