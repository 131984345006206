* {
  margin: 0;
  padding: 0;
  font-family: myFont;
}

html, body {
  height: 100%;
}

.home,
.skills,
.projects,
.about,
.contact {
  height: 100%;
}


@font-face {
  font-family: myFont;
  src: url(../src/fonts/aboutme-header-font.otf)
}

body::-webkit-scrollbar{
  display: none;
}

