.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('../images/galaxy-bg.jpeg');
  background-size: cover;
  height: calc(100vh - 140px);
}

.skills-inner-container {
  margin-left: 200px;
  margin-right: 200px;
}

.skills-title {
  margin-bottom: 30px;
  color: #2596be;
  font-size: 3em;
  font-family: headerFont;
  text-decoration: underline #2596be;
  padding-bottom: 10px;
  text-shadow: 2px 3px 4px hotpink;
}

.skill-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 20px;
  margin: auto;
}

.skills-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.stack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.skills-item svg {
  width: 30px;
  height: 300px;
  margin: auto;
}

@media screen and (max-width: 767px) {

  .skills-container {
    height: auto;
  }
  .skill-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    grid-gap: 20px;
    margin: auto;
  }
}